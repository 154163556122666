import React from "react";

function SvgLogo(props) {
  // width={170} height={96}
  return (
  <svg id="logo" data-name="Logo - Ellite" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 276.958 96.221">
  <g id="Group_9" data-name="Group 9">
    <g id="Group_3" data-name="Group 3">
      <path id="Path_1" data-name="Path 1" d="M89.012,25a48.111,48.111,0,1,0,48.111,48.111A48.11,48.11,0,0,0,89.012,25Z" transform="translate(-40.901 -25)" fill={props.fill}/>
      <path id="Path_2" data-name="Path 2" d="M140.327,97.409h4.779a37.051,37.051,0,1,0-1.9,7.654H137.8a32.154,32.154,0,1,1,2.485-15.308H101.048V82.465h21.936l-.675-13.133-8.437,4.041L91.936,72.7v40.746h26.387l6.416,2.6V104.334H101.777V97.409Z" transform="translate(-60.191 -44.195)" fill={props.bleed}/>
    </g>
    <g id="Group_4" data-name="Group 4" transform="translate(98.645 18.953)">
      <path id="Path_3" data-name="Path 3" d="M315.995,128.746l.957-9.112h1.822L321.8,90.841h-2.916L319.92,81h17.875l-1.034,9.841h-2.187l-3.026,28.794h9.825l.307-2.916h9.128l-1.264,12.028Z" transform="translate(-314.376 -79.542)" fill={props.fill}/>
      <path id="Path_4" data-name="Path 4" d="M333.352,79.916l-.728,6.925h-2.187L327.1,118.55h12.757l.307-2.916h6.2l-.307,2.916-.345,3.28-.307,2.916H314.79l.651-6.2h1.822L320.6,86.841h-2.916l.728-6.925h14.943M336.591,77H315.784l-.274,2.611-.728,6.925-.339,3.22h2.916l-2.72,25.878h-1.822l-.274,2.611-.651,6.2-.339,3.221h36.479l.274-2.611.307-2.916.345-3.28.307-2.916.339-3.221h-12.06l-.275,2.611-.032.3h-6.893l2.72-25.878h2.187l.274-2.611.728-6.925.339-3.22Z" transform="translate(-311.552 -77)" fill={props.fill}/>
      <path id="Path_5" data-name="Path 5" d="M335.47,81.822l-.728,6.925h-2.187l-3.333,31.709h12.757l.307-2.916h6.2l-.307,2.916-.345,3.28-.307,2.916H316.908l.651-6.2h1.822l3.333-31.709H319.8l.728-6.925H335.47M337.494,80H318.886l-.172,1.632-.728,6.925-.211,2.013h2.916l-2.95,28.065h-1.822l-.172,1.632-.651,6.2-.211,2.013h34.281l.172-1.632.306-2.916.345-3.28.307-2.916.211-2.013h-9.861l-.172,1.632-.135,1.284h-9.092L334.2,90.57h2.187l.172-1.632.728-6.925L337.494,80Z" transform="translate(-313.67 -78.907)" fill={props.bleed}/>
    </g>
    <g id="Group_5" data-name="Group 5" transform="translate(137.28 18.953)">
      <path id="Path_6" data-name="Path 6" d="M421.994,128.746l.958-9.112h1.822L427.8,90.841h-2.916L425.919,81h17.875l-1.034,9.841h-2.187l-3.026,28.794h9.825l.307-2.916h9.128l-1.264,12.028Z" transform="translate(-420.375 -79.542)" fill={props.fill}/>
      <path id="Path_7" data-name="Path 7" d="M439.352,79.916l-.728,6.925h-2.187L433.1,118.55h12.757l.307-2.916h6.2l-.307,2.916-.345,3.28-.307,2.916H420.79l.651-6.2h1.822L426.6,86.841h-2.916l.728-6.925h14.943M442.591,77H421.784l-.274,2.611-.728,6.925-.339,3.22h2.916l-2.72,25.878h-1.822l-.274,2.611-.651,6.2-.339,3.221h36.48l.274-2.611.307-2.916.345-3.28.307-2.916.339-3.221h-12.06l-.274,2.611-.032.3h-6.893l2.72-25.878h2.187l.274-2.611.728-6.925.339-3.22Z" transform="translate(-417.552 -77)" fill={props.fill}/>
      <path id="Path_8" data-name="Path 8" d="M441.47,81.822l-.728,6.925h-2.187l-3.333,31.709h12.757l.307-2.916h6.2l-.307,2.916-.345,3.28-.307,2.916H422.908l.651-6.2h1.822l3.333-31.709H425.8l.728-6.925H441.47M443.494,80H424.886l-.172,1.632-.728,6.925-.211,2.013h2.916l-2.95,28.065h-1.822l-.172,1.632-.651,6.2-.211,2.013h34.281l.172-1.632.307-2.916.345-3.28.307-2.916.211-2.013h-9.861l-.172,1.632-.135,1.284h-9.092L440.2,90.57h2.187l.172-1.632.728-6.925L443.494,80Z" transform="translate(-419.67 -78.907)" fill={props.bleed}/>
    </g>
    <g id="Group_6" data-name="Group 6" transform="translate(175.302 18.953)">
      <path id="Path_9" data-name="Path 9" d="M526.26,128.746l.844-9.112h2.568L532.7,90.841h-2.187L531.546,81h16.782l-1.034,9.841h-2.916l-3.026,28.794h2.531l-.844,9.112Z" transform="translate(-524.661 -79.542)" fill={props.fill}/>
      <path id="Path_10" data-name="Path 10" d="M543.92,79.916l-.728,6.925h-2.916l-3.333,31.709H539.5l-.575,6.2H525.07l.575-6.2H528.2l3.333-31.709h-2.187l.728-6.925h13.85M547.159,77H527.445l-.274,2.611-.728,6.925-.339,3.22h2.187l-2.72,25.878h-2.584l-.245,2.646-.575,6.2-.3,3.185h19.706l.245-2.646.575-6.2.3-3.185h-2.512l2.72-25.878h2.916l.274-2.611.728-6.925.339-3.22Z" transform="translate(-521.872 -77)" fill={props.fill}/>
      <path id="Path_11" data-name="Path 11" d="M546.011,81.822l-.728,6.925h-2.916l-3.333,31.709h2.551l-.575,6.2h-13.85l.575-6.2h2.551l3.333-31.709h-2.187l.728-6.925h13.85M548.035,80H530.52l-.172,1.632-.728,6.925-.211,2.013H531.6l-2.95,28.065h-2.572l-.153,1.654-.575,6.2-.184,1.991h17.51l.153-1.654.575-6.2.185-1.991h-2.527l2.95-28.065h2.916l.172-1.632.728-6.925L548.035,80Z" transform="translate(-523.963 -78.907)" fill={props.bleed}/>
    </g>
    <g id="Group_7" data-name="Group 7" transform="translate(200.025 18.953)">
      <path id="Path_12" data-name="Path 12" d="M600.767,128.746l.966-10.205h2.383l2.911-27.7h-4.175l-.307,2.916h-8.4L594.7,88.5l.7-7.5h36.824l-.722,7.788-.523,4.968h-8.4l.307-2.916h-4.175l-2.911,27.7h2.352l-.965,10.205Z" transform="translate(-592.527 -79.542)" fill={props.fill}/>
      <path id="Path_13" data-name="Path 13" d="M627.8,79.916l-.575,6.2-.077.729-.307,2.916h-5.467l.307-2.916h-7.107l-3.218,30.616h2.369l-.69,7.29H599.547l.69-7.29h2.369l3.218-30.616h-7.107l-.307,2.916h-5.467l.307-2.916.077-.729.575-6.2h33.9m3.2-2.916H591.242L591,79.646l-.573,6.178-.075.711-.307,2.916-.339,3.221h11.331l.274-2.611.032-.3h1.244l-2.6,24.784h-2.4l-.25,2.641-.69,7.289-.3,3.19h19.343l.25-2.641.69-7.289.3-3.19h-2.336l2.6-24.784h1.244l-.306,2.916h11.331l.274-2.611.307-2.916.077-.729,0-.018,0-.018.575-6.2.3-3.185Z" transform="translate(-589.704 -77)" fill={props.fill}/>
      <path id="Path_14" data-name="Path 14" d="M629.914,81.822l-.575,6.2-.077.729-.307,2.916h-5.467l.307-2.916h-7.107l-3.218,30.616h2.369l-.69,7.29H601.664l.69-7.29h2.369l3.218-30.616h-7.107l-.307,2.916H595.06l.307-2.916.076-.729.575-6.2h33.9m2-1.822H594.357l-.154,1.654-.574,6.185-.076.718-.307,2.916-.211,2.013h9.132l.172-1.632.135-1.284h3.443l-2.835,26.971H600.7l-.156,1.651-.69,7.29-.189,1.994h17.146l.156-1.651.69-7.289.189-1.994h-2.348l2.835-26.971h3.443l-.095.9-.211,2.013H630.6l.172-1.632.307-2.916.076-.729v-.022l.575-6.2L631.913,80Z" transform="translate(-591.822 -78.907)" fill={props.bleed}/>
    </g>
    <g id="Group_8" data-name="Group 8" transform="translate(234.812 18.953)">
      <path id="Path_15" data-name="Path 15" d="M689.478,128.746l.8-10.205h5.5l2.912-27.7h-2.169L697.452,81h30.993l-.8,8.52-.368,3.508h-8.4l.23-2.187h-9.46l-1.034,9.841h1.806l.23-2.187h8.4l-1.341,12.757h-8.4l.191-1.822h-1.806l-.958,9.112h8.731l.23-2.187h8.763l-.512,4.868-.558,7.524Z" transform="translate(-687.9 -79.542)" fill={props.fill}/>
      <path id="Path_16" data-name="Path 16" d="M724.092,79.916l-.651,6.925-.23,2.187h-5.467l.23-2.187H705.582L704.241,99.6h4.738l.23-2.187h5.467l-1.034,9.841h-5.467l.192-1.822h-4.738l-1.264,12.028h11.663l.23-2.187h5.832l-.23,2.187-.115,1.093-.459,6.2H688.3l.575-7.29h5.467l3.218-30.616h-2.187l.651-6.925h28.064M727.3,77H693.373l-.249,2.643-.651,6.925-.3,3.189h2.152l-2.6,24.784h-5.537l-.212,2.687-.575,7.289-.248,3.145h36.842l.2-2.7.456-6.151.11-1.049.23-2.187.339-3.22h-11.7l-.23,2.187h-5.8l.46-4.374h10.205l.274-2.611,1.034-9.841.339-3.22H707.709l.5-4.738h6.528l-.23,2.187h11.331l.275-2.611.23-2.187,0-.016v-.016L727,80.189,727.3,77Z" transform="translate(-685.149 -77)" fill={props.fill}/>
      <path id="Path_17" data-name="Path 17" d="M726.155,81.822l-.651,6.925-.23,2.187h-5.467l.23-2.187H707.645L706.3,101.5h4.738l.23-2.187h5.467l-1.034,9.841h-5.467l.192-1.822h-4.738l-1.264,12.028H716.09l.23-2.187h5.832l-.23,2.187-.115,1.093-.46,6.2h-30.98l.575-7.29h5.467l3.218-30.616H697.44l.651-6.925h28.064m2-1.822H696.432l-.155,1.652-.651,6.925-.187,1.993H697.6l-2.835,26.971h-5.51l-.132,1.679-.574,7.289-.155,1.966h34.644l.125-1.688.458-6.168.112-1.065.23-2.187.211-2.013h-9.5l-.172,1.632-.058.555h-8l.881-8.383h1.073l-.192,1.822h9.132l.172-1.632,1.034-9.841.211-2.013h-9.132l-.172,1.632-.058.555h-1.073l.958-9.112h8.727l-.018.174-.211,2.013h9.132l.172-1.632.23-2.187v-.02l.651-6.925L728.157,80Z" transform="translate(-687.212 -78.907)" fill={props.bleed}/>
    </g>
  </g>
  <g id="Group_11" data-name="Group 11" transform="translate(97.898 73.441)">
    <g id="Group_10" data-name="Group 10">
      <path id="Path_18" data-name="Path 18" d="M318.247,238.163h1.822v2.919l-2.389,2.548h-5.79l-2.39-2.548V229.048l2.39-2.548h5.79l2.389,2.548v2.919h-1.822v-1.859l-1.435-1.421h-3.941l-1.185,1.159v10.439l1.162,1.159h3.941l1.458-1.421Z" transform="translate(-309.5 -226.5)" fill={props.fill}/>
      <path id="Path_19" data-name="Path 19" d="M349.821,243.63l-2.321-2.513V229.012l2.321-2.513h6.293l2.321,2.513v12.105l-2.321,2.513Zm.656-1.822h4.594l1.177-1.2V229.889l-1.154-1.2H350.5l-1.177,1.2V240.6Z" transform="translate(-333.65 -226.499)" fill={props.fill}/>
      <path id="Path_20" data-name="Path 20" d="M391.145,241.808H392.6v1.822h-5.1v-1.822h1.458V228.322H387.5V226.5h5.217l6.082,14.511V228.322h-1.458V226.5h5.1v1.822h-1.458V243.63h-3.158l-6.683-14.488Z" transform="translate(-359.071 -226.5)" fill={props.fill}/>
      <path id="Path_21" data-name="Path 21" d="M447.705,231.966h-2.187v-1.874l-1.32-1.406h-3.367l-1.144,1.2v3.13l1.078,1.135h4.983l2.321,2.375v4.638l-2.39,2.465h-5.79l-2.39-2.465v-3h1.822v1.924l1.339,1.356h4.079l1.142-1.155v-3.246l-1.075-1.067h-4.658l-2.284-2.331v-4.6l2.326-2.548h5.189l2.326,2.548v2.919Z" transform="translate(-390.847 -226.499)" fill={props.fill}/>
      <path id="Path_22" data-name="Path 22" d="M480.976,243.63h-6.561v-1.822H476.6V228.322h-3.28v2.551H471.5V226.5h12.028v4.374H482.07v-2.551h-3.28v13.486h2.187Z" transform="translate(-412.455 -226.5)" fill={props.fill}/>
      <path id="Path_23" data-name="Path 23" d="M510.5,226.5h9.257l2.407,2.513v5.026l-2.407,2.513,2.476,5.255h1.024v1.822h-4.374v-1.822h.816l-2.239-5.1h-2.951v5.1H515.6v1.822h-5.1v-1.822h1.822V228.323H510.5Zm4.009,1.822v6.2h4.51l1.321-1.364v-3.468l-1.3-1.364h-4.533Z" transform="translate(-437.241 -226.5)" fill={props.fill}/>
      <path id="Path_24" data-name="Path 24" d="M562.247,226.5h5.1v1.822h-1.458v12.795l-2.369,2.513h-6.2l-2.369-2.513V228.322H553.5V226.5h5.1v1.822h-1.458v11.93l1.13,1.191h4.278l1.153-1.191v-11.93h-1.458Z" transform="translate(-464.568 -226.5)" fill={props.fill}/>
      <path id="Path_25" data-name="Path 25" d="M608.882,238.163h2.187v2.919l-2.39,2.548h-5.79l-2.39-2.548V229.048l2.39-2.548h5.79l2.39,2.548v2.919h-2.187v-1.859l-1.435-1.421h-3.941l-1.185,1.159v10.439l1.162,1.159h3.941l1.458-1.421Z" transform="translate(-494.437 -226.5)" fill={props.fill}/>
      <path id="Path_26" data-name="Path 26" d="M643.612,243.63h-6.561v-1.822h2.187V228.322h-3.28v2.551H634.5V226.5h12.028v4.374h-1.822v-2.551h-3.28v13.486h2.187Z" transform="translate(-516.046 -226.5)" fill={props.fill}/>
      <path id="Path_27" data-name="Path 27" d="M672.5,241.808h1.822V228.322H672.5V226.5h5.832v1.822h-1.822v13.486h1.822v1.822H672.5Z" transform="translate(-540.196 -226.5)" fill={props.fill}/>
      <path id="Path_28" data-name="Path 28" d="M699.821,243.63l-2.321-2.513V229.012l2.321-2.513h6.293l2.321,2.513v12.105l-2.321,2.513Zm1.02-1.822h4.594l1.177-1.2V229.889l-1.154-1.2h-4.594l-1.177,1.2V240.6Z" transform="translate(-556.084 -226.499)" fill={props.fill}/>
      <path id="Path_29" data-name="Path 29" d="M741.78,241.808H743.6v1.822h-5.1v-1.822h1.458V228.322H738.5V226.5h4.853l6.082,14.511V228.322h-1.458V226.5h5.1v1.822h-1.458V243.63h-3.158l-6.683-14.488Z" transform="translate(-582.14 -226.5)" fill={props.fill}/>
    </g>
  </g>
</svg>

  );
}

export default SvgLogo;